<template>
  <div class="demo-ads-container">
    <div class="title-ads-demo mb-1">
      <b-card-title class="title-ads-demo__content d-flex">
        {{ validAmount ? `Transfer money from ${platformName} Balance to Ad account balance` :'Congrats, your Demo Advertiser Account is ready!' }}
      </b-card-title>
      <b-card-text class="title-ads-demo__sub-title">
        {{ validAmount ?'' : 'Now, you can explore TikTok\'s ad manager, know how to create an ad campaign and stats required for a successful campaign.' }}
      </b-card-text>
    </div>
    <div
      v-if="validAmount"
      class="amount-balance"
    >
      <b-card-text class="ecomdy-balance d-flex align-items-center">
        <div>
          Available {{ platformName }} balance: <span
            class="amount font-20 font-weight-bold"
            data-testid="onboarding-ecomdy-balance"
          >{{ currencyFormat(amountBalance) }}</span>
        </div>
        <img
          v-if="isShowBtnAddFund"
          v-b-tooltip.hover.top.v-light="'Add fund'"
          id="trigger-add-balance-ecomdy-ads-demo"
          :src="require('@/assets/images/common/ic-add-fund-yellow.svg')"
          alt="image"
          class="cursor-pointer"
          style="margin-left: 10px"
          @click="goToAddFund"
        >
      </b-card-text>
    </div>

    <div class="content-ads-demo">
      <div class="top-content">
        <b-row>
          <b-col sm="6">
            <b-card-text
              class="text-content"
            >
              Ad Account
            </b-card-text>
          </b-col>
          <b-col
            sm="6"
            class="d-none d-md-block"
          >
            <b-card-text
              class="text-content"
            >
              Business Account
            </b-card-text>
          </b-col>
        </b-row>
      </div>
      <div class="info-ads-demo">
        <b-row>
          <b-col
            sm="6"
            class="content-left"
          >
            <div class="d-flex">
              <div class="icon-ads-account">
                <b-img
                  :src="require('@/assets/images/pages/adsAccount/icon-ads-account.svg')"
                />
              </div>
              <div class="d-flex flex-column">
                <b-link
                  class="info-ad-account__content"
                  :href="
                    `https://ads.tiktok.com/i18n/dashboard?aadvid=${accountDemo && accountDemo._id}`
                  "
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {{ accountDemo && accountDemo.name }}
                </b-link>
                <span class="info-ad-account__sub">ID: {{ accountDemo && accountDemo._id }}</span>
              </div>
            </div>
          </b-col>

          <div class="border-top-content d-md-none w-100 mx-2">
            <b-card-text
              class="text-content"
            >
              Business Account
            </b-card-text>
          </div>

          <b-col
            sm="6"
            class="content-right"
          >
            <div class="d-flex">
              <div class="icon-ads-account">
                <b-img
                  :src="require('@/assets/images/pages/adsAccount/icon-business-account.svg')"
                />
              </div>
              <div class="info-ad-account">
                <b-card-text class="info-ad-account__sub">
                  ID: {{ accountDemo && accountDemo.partnerBCId }}
                </b-card-text>
              </div>
            </div>
          </b-col>
          <b-col />
        </b-row>
      </div>
      <div class="notice-content">
        <div v-if="!validAmount">
          <b-card-text class="notice-content__content">
            🔥 Notice: This is demo advertiser accounts, to launch your advertising campaign, you must first add funds.
          </b-card-text>
        </div>

        <div v-else>
          <b-row class="align-items-center">
            <b-col
              md="6"
            >
              <div class="ads-balance">
                <b-card-text class="font-14">
                  Ad Account balance: {{ currencyFormat(amountConverted) }}
                </b-card-text>
              </div>
            </b-col>
            <b-col
              v-if="isShowBtnAddFund"
              md="6"
            >
              <div class="btn-control">
                <btn-loading
                  variant-convert="btn-submit"
                  pill
                  @click="submit"
                >
                  Transfer money to Ad account
                </btn-loading>
                <btn-loading
                  class="font-medium font-14 mr-50 mt-1 mt-md-0"
                  variant-convert="flat-dark"
                  type="submit"
                  pill
                  @click.prevent="$router.push({ name: 'waiting-verify-information' })"
                >
                  Back
                </btn-loading>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>

    <div
      v-if="!isShowBtnAddFund"
      class="btn-control"
    >
      <btn-loading
        variant-convert="btn-submit"
        pill
        @click="submit"
      >
        Ready to run campaign
      </btn-loading>
    </div>

    <add-fund-modal
      ref="open-handle-balance-account-modal"
      :open-add-fund-modal="openAddFundModal"
      :account="accountSelected"
      :user-info="user"
    />

  </div>
</template>

<script>
import {
  BCardTitle,
  BCardText,
  BRow,
  BCol,
  BImg,
  BLink,
  VBTooltip,
} from 'bootstrap-vue'
import * as animationData from '@/assets/images/animations/confetti-effect.json'
import { createNamespacedHelpers } from 'vuex'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import AddFundModal from '@/views/payment/components/TabsHistory/Modal/AddFundModal.vue'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import { ONBOARDING_STEP } from '@/constants'
import BtnLoading from '@/layouts/components/BtnLoading.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('adsAccount')
const { mapGetters: mapGettersAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    BtnLoading,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BImg,
    BLink,
    AddFundModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  mixins: [numberFormatMixin, generalConfigsMixin, stepGuideMixin],

  data() {
    return {
      defaultOptions: {
        animationData: animationData.default,
      },
      openAddFundModal: false,
      accountSelected: {},
    }
  },

  computed: {
    ...mapGetters(['listAccount']),
    ...mapGettersAuth(['user']),

    isShowBtnAddFund() {
      return this.validAmount && this.setupGuideStep === ONBOARDING_STEP.DEPOSITING_MONEY
    },

    amountBalance() {
      return this.user?.data?.balance
    },

    validAmount() {
      return this.amountBalance > 0
    },

    accountDemo() {
      return this.listAccount?.content[0]
    },

    amountConverted() {
      const currency = this.listAccount?.content[0]?.currency
      const balance = this.listAccount?.content[0]?.balance
      const rate = this.USDToVNDRate
      return currency === 'VND' ? balance / rate : balance
    },
  },

  created() {
    this.fetchListAdsAccount()
  },

  // mounted() {
  //   this.openCongrats()
  //   setTimeout(() => {
  //     this.closeCongrats()
  //   }, 6000)
  // },

  methods: {
    ...mapActions(['getAccountList']),
    ...mapActionsAuth(['getAccountInfo']),

    // openCongrats() {
    //   this.$refs['congrats-modal'].showModal()
    // },

    // closeCongrats() {
    //   this.$refs['congrats-modal'].hideModal()
    // },

    // handleAnimation(anim) {
    //   this.anim = anim
    //   setTimeout(() => { this.anim.stop() }, 6000)
    // },

    async submit() {
      if (this.isShowBtnAddFund) {
        this.openAddFundModal = true
        this.$refs['open-handle-balance-account-modal'].showModal()
        this.accountSelected = this.accountDemo
      } else {
        await this.getAccountInfo()
        this.$router.push({ name: 'update-information-business' })
      }
    },

    async fetchListAdsAccount() {
      const params = {
        page: 1,
        size: 10,
      }
      if (this.listAccount.paging.total === 0) {
        await this.getAccountList(params)
      }
    },

    goToAddFund() {
      if (this.isEcomdyPlatform && !this.isExclusivePartner) {
        this.$router.push('/payment/add-fund?type=recharge&tab=paypal')
      } else {
        this.$router.push('/payment/add-fund?type=recharge')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .demo-ads-container{
    padding: 24px;
    // margin-left: 10.5px;

    background-color: #fff;
    border-radius: var(--border-radius-base);

    .confetti-effect{
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 999;
    }

    .title-ads-demo{
      width: 90%;
      &__content{
        font-size: 20px;

        margin-bottom: 10px;
      }

      &__sub-title{
        font-size: 14px;
      }
    }

    .amount-balance{
      margin-bottom:  32px ;
    }

    .content-ads-demo{
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      padding: 16px;
      margin-bottom: 20px;

      .top-content{
        border-bottom:  1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;

        @media(max-width: 767px) {
          border-bottom:  0px;
          margin-bottom: 0px;
        }
      }

      .border-top-content {
          border-top:  1px solid rgba(0, 0, 0, 0.1);
          margin-top: 1rem;
          padding-top: 1rem;
      }

      .text-content{
          padding-bottom: 10px;
          color: rgba(0, 0, 0, 0.698);
          font-size: 12px;
        }

      .info-ads-demo{
        border-bottom:  1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 16px;
        padding-bottom: 17px;

        .content-left, .content-right{

          .icon-ads-account{
            margin-right: 12px;
          }

          .info-ad-account{

            &__content{
              font-size: 14px;
              color: #000000;
              text-decoration: underline;

              margin-bottom: 0;

              cursor: pointer;
            }

            &__sub{
              font-size: 12px;
              color: rgba(0, 0, 0, 0.7);
            }
          }
        }

        .content-right{
          .info-ad-account{
            &__content{
              text-decoration: none;

              cursor: auto;
            }
          }
        }
      }

      .notice-content{
        &__content{
          font-size: 12px;

          font-style: italic;
        }
      }
    }

    .btn-control{
      justify-content: end;

      @media(min-width: 768px) {
        display: flex;
        flex-direction: row-reverse;
      }

      button {
        @media(max-width: 767px) {
          width: 100%;
          margin-top: 1rem;
        }
      }

      .btn-create{
        padding: 11px 18px;
        margin: 0 9px;

        cursor: pointer;

        font-size: 14px;
      }
    }
  }

  .guide-icon {
    top: 52px;
    right: 23px;
  }
</style>
